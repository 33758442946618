module.exports = {
  clickOnNDP: 'Haga clic en el año para acceder a los datos originales',
  nationalDataPoint: 'Punto de datos nacionales',
  addNationalDataPoint: 'Añadir un punto de datos nacionales',
  noNationalDataAdded: 'No se ha añadido ningún punto de datos nacionales',
  nationalData: 'Datos nacionales',
  reclassificationLabel: 'Datos originales y reclasificación',
  forestCategoriesLabel: 'El bosque, otras tierras boscosas y otras tierras',
  referenceYearData: 'Año de referencia para los datos',
  referenceYear: 'Año de referencia',
  references: 'Referencias',
  selectYear: 'Seleccionar…',
  methods: 'Métodos',
  methodsUsed: 'Métodos usados',
  dataSource: 'Fuente de datos',
  dataSources: 'Fuentes de datos',
  additionalComments: 'Comentarios adicionales',
  edit: 'Editar',
  copyPreviousValues: 'Copiar las referencias anteriores',
  nationalClass: 'Clase nacional',
  nationalClasses: 'Clasificaciones y definiciones',
  definition: 'Definición',
  class: 'Clase',
  fraClasses: 'Clases de FRA',
  area: 'Área (1000 ha)',
  total: 'Total',
  modifiedExplanation: 'Punto de datos nacionales con modificaciones',
  confirmDelete: '¿Borrar este punto de datos? Esta acción no puede deshacerse.',
  discardChanges: 'Cancelar cambios',
  doneEditing: 'Edición finalizada',
  enterOrCopyPasteNationalClasses: 'Introducir o copiar y pegar las clases nacionales',
  forestCharacteristics: 'Características de los bosques',
  otherLandCharacteristics: 'Otras tierras con cubierta de árboles',
  plantationForest: 'Plantación forestal',
  remindDirtyOdp: 'El punto de datos nacionales ha sido actualizado, recuerde restablecer los valores',
  disabled: 'Desactivado, activar en el apartado 1b',
  dataSourceMethodsOptions: {
    nationalForestInventory: 'Inventario nacional de los bosques',
    sampleBasedRemoteSensingAssessment: 'Evaluación de teledetección por muestreo',
    fullCoverMaps: 'Bosque de cubierta total/mapas de vegetación',
    registersQuestionnaires: 'Registros/cuestionarios',
    other: 'Otros (especificar en comentarios)',
  },
  appliesToVariablesOptions: {
    forest: 'Bosque',
    otherWoodedLand: 'Otras tierras boscosas',
    otherLand: 'Otras tierras',
  },
  forestCategoriesLabel2025: 'Bosque, Otras Tierras Boscosas y Superfície Terrestre Restante',
  nationalClassifications: 'Clasificaciones nacionales',
  categories: 'Categorías',
  fileAddedWillBecomePublic: 'Los archivos añadidos como referencias serán de acceso público',
}
