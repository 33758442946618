module.exports = {
  clickOnNDP: '点击年份获取原始数据',
  nationalDataPoint: '国家数据点',
  addNationalDataPoint: '添加国家数据点',
  noNationalDataAdded: '未添加国家数据',
  nationalData: '国家数据',
  reclassificationLabel: '原始数据和重新分类',
  forestCategoriesLabel: '森林、其他林地和其他土地',
  referenceYearData: '数据参考年份',
  referenceYear: '参考年份',
  references: '参考',
  selectYear: '选择…',
  methods: '方法',
  methodsUsed: '已使用的方法',
  dataSource: '数据来源',
  dataSources: '数据来源',
  additionalComments: '额外评论',
  edit: '编辑',
  copyPreviousValues: '复制先前的参考文献',
  nationalClass: '国家界定的类别',
  nationalClasses: '分类和定义',
  definition: '定义',
  class: '类别',
  fraClasses: 'FRA界定的类别',
  area: '面积(1000公顷)',
  total: '总计',
  modifiedExplanation: '修正后的国家数据点',
  confirmDelete: '确定删除这一数据点吗？此操作不可撤销。',
  discardChanges: '放弃修改',
  doneEditing: '编辑完成',
  enterOrCopyPasteNationalClasses: '输入或复制与粘贴国家界定的类别',
  forestCharacteristics: '森林特征',
  otherLandCharacteristics: '有树木覆盖的其他土地',
  plantationForest: '种植林',
  remindDirtyOdp: '国家数据点已经更新，请记得重新生成数值',
  disabled: '第1b项的禁用、启用',
  dataSourceMethodsOptions: {
    nationalForestInventory: '国家森林资源清查',
    sampleBasedRemoteSensingAssessment: '基于采样的遥感评估',
    fullCoverMaps: '全幅森林/植被图',
    registersQuestionnaires: '登记/调查表',
    other: '其他 (在评论中注明)',
  },
  appliesToVariablesOptions: {
    forest: '森林',
    otherWoodedLand: '其他林地',
    otherLand: '其他土地',
  },
  fileAddedWillBecomePublic: '添加的参考文件将可公开访问',
  forestCategoriesLabel2025: '森林、其他林地和其它土地',
  nationalClassifications: '国家级分类',
  categories: '类别',
}
