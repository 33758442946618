module.exports = {
  clickOnNDP: `Cliquez sur l'année pour accéder aux données originales`,
  nationalDataPoint: 'Point des données nationales',
  addNationalDataPoint: 'Ajouter point des données nationales',
  noNationalDataAdded: 'Pas de donnée nationale ajoutée',
  nationalData: 'Donnée nationale',
  reclassificationLabel: 'Données de base et reclassification',
  forestCategoriesLabel: 'Forêt, autres terres boisées et autre terre',
  referenceYearData: 'Année de référence pour les données',
  referenceYear: 'Année de référence',
  references: 'Références',
  selectYear: 'Sélectionner…',
  methods: 'Méthodes',
  methodsUsed: 'Méthodes utilisées',
  dataSource: 'Source des données',
  dataSources: 'Source des données',
  additionalComments: 'Commentaires supplémentaires',
  edit: 'Modifier',
  copyPreviousValues: 'Copier les références précédentes',
  nationalClass: 'Classe nationale',
  nationalClasses: 'Classifications et définitions',
  definition: 'Définition',
  class: 'Classe',
  fraClasses: 'Classes de FRA',
  area: 'Superficie (1000 ha)',
  total: 'Total',
  modifiedExplanation: 'Point des données nationales avec modifications',
  confirmDelete: 'Supprimer ce point des données? Cette action ne peut pas être annulée.',
  discardChanges: 'Annuler les modifications',
  doneEditing: 'Terminée',
  enterOrCopyPasteNationalClasses: 'Saisir ou copier-coller les classes nationales',
  forestCharacteristics: 'Caractéristiques des forêts',
  otherLandCharacteristics: 'Autre terre dotée de couvert arboré',
  plantationForest: 'Forêt de plantation',
  remindDirtyOdp: 'Le point des données nationales a été actualisé, générer nouvelles valeurs',
  disabled: 'Désactivé, activer dans la section 1b',
  dataSourceMethodsOptions: {
    nationalForestInventory: 'Inventaire national des forêts',
    sampleBasedRemoteSensingAssessment: "Évaluation d'échantillons par télédétection",
    fullCoverMaps: 'Cartes complètes des forêts/de la végétation',
    registersQuestionnaires: 'Répertoires/questionnaires',
    other: '$t(common.otherSpecifyInComments)',
  },
  appliesToVariablesOptions: {
    forest: 'Forêt',
    otherWoodedLand: 'Autres terres boisées',
    otherLand: 'Autre terre',
  },
  forestCategoriesLabel2025: 'Forêt, autres terres boisées et terres restantes',
  nationalClassifications: 'Classifications nationales',
  categories: 'Catégories',
  fileAddedWillBecomePublic: 'Un fichier ajouté comme référence sera accessible au public',
}
