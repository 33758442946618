module.exports = {
  clickOnNDP: 'Нажмите на год, чтобы получить доступ к исходным данным',
  nationalDataPoint: 'Национальная точка данных',
  addNationalDataPoint: 'Добавить национальный отчетный год',
  noNationalDataAdded: 'Национальные данные не добавлены',
  nationalData: 'Национальные данные',
  reclassificationLabel: 'Исходные данные и реклассификация',
  forestCategoriesLabel: 'Леса, прочие лесопокрытые земли и другие земельные площади',
  referenceYearData: 'Отчетный год для данных',
  referenceYear: 'Отчетный год',
  references: 'Справочные материалы',
  selectYear: 'Выбрать…',
  methods: 'Методы',
  methodsUsed: 'Используемые методы',
  dataSource: 'Источник данных',
  dataSources: 'Источники данных',
  additionalComments: 'Дополнительные комментарии',
  edit: 'Редактировать',
  copyPreviousValues: 'Копировать предыдущие ссылки',
  nationalClass: 'Национальный класс',
  nationalClasses: 'Классификации и определения',
  definition: 'Определение',
  class: 'Класс',
  fraClasses: 'Классы ОЛР',
  area: 'Площадь (1000 га)',
  total: 'Итого',
  modifiedExplanation: 'Национальный отчетный год с изменениями',
  confirmDelete: 'Удалить этот отчетный год? Его невозможно будет восстановить.',
  discardChanges: 'Отменить изменения',
  doneEditing: 'Завершить редактирование',
  enterOrCopyPasteNationalClasses: 'Введите или скопируйте и вставьте национальные классы',
  forestCharacteristics: 'Характеристики лесов',
  otherLandCharacteristics: 'Другие лесистые земли',
  plantationForest: 'Плантационные лесные культуры',
  remindDirtyOdp: 'Национальный отчетный год был обновлен, не забудьте регенерировать значения',
  disabled: 'Заблокировано, разблокируйте в разделе 1b',
  dataSourceMethodsOptions: {
    nationalForestInventory: 'Национальная инвентаризация лесов',
    sampleBasedRemoteSensingAssessment: 'Выборочная оценка с помощью дистанционного зондирования',
    fullCoverMaps: 'Полные карты лесов/растительности',
    registersQuestionnaires: 'Реестры/опросники',
    other: '$t(common.otherSpecifyInComments)',
  },
  appliesToVariablesOptions: {
    forest: 'Леса',
    otherWoodedLand: 'Прочие лесопокрытые земли',
    otherLand: 'Другие земельные площади',
  },
  forestCategoriesLabel2025: 'Леса, прочие лесистые земли и остальные земельные площади',
  nationalClassifications: 'Национальные классификации',
  categories: 'Категории',
  prefill: 'Предварительно заполнить',
  prefillWith: 'Предварительно заполнить с',
  dataSource2025ExplanatoryText:
    'Перечисленные источники данных были представлены в ОЛР-2020. Просьба скопировать и вставить соответствующие источники данных в таблицу выше.',
  confirmCopyPreviousValues: 'Вы хотите изменить предыдущие значения? Эту операцию нельзя отменить.',
  fileAddedWillBecomePublic: 'Файл, добавленный в качестве справочного документа, станет общедоступным',
}
