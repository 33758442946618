module.exports = {
  accepted: 'Accepté',
  add: 'Ajouter',
  all: 'Toutes',
  apply: 'Appliquer',
  approve: 'Appouver',
  approved: 'Approuvé',
  areYouSureYouWantToDelete: 'Êtes-vous sûr de vouloir supprimer cet élément ? Cette opération ne peut être annulée.',
  calculate: 'Calculer',
  cancel: 'Effacer',
  clearTable: 'Supprimer tableau',
  column: 'Colonne',
  continue: 'Continuer',
  copyToClipboard: 'Copier dans le presse-papiers',
  countries: 'Pays',
  country: 'Pays',
  custom: 'Personnaliser',
  dashboard: 'Tableau de bord',
  dataExport: 'Exportation de données',
  delete: 'Supprimer',
  disapprove: 'Refuser',
  email: 'Email',
  empty: 'Vide',
  file: 'Fichier',
  filterCountries: 'Filtrer les pays',
  fraPlatform: 'Plateforme de FRA',
  globalFRA: 'Évaluation des ressources forestières mondiales',
  hide: `Cacher`,
  issues: 'Problèmes',
  label: 'Nom',
  language: 'Langue',
  lastEdit: 'Dernière modification',
  latest: 'Dernier',
  link: 'Lien',
  load: 'Charger',
  loading: 'Chargement en cours...',
  login: 'Espace réservé',
  name: 'Nom',
  or: 'Ou',
  other: 'Autre',
  otherSpecifyInComments: 'Autre (préciser dans les commentaires)',
  private: 'Privé',
  public: 'Public',
  regions: 'Régions',
  resetAll: 'Effacer tout',
  role: 'Rôle',
  select: 'Choisissez',
  selectAll: 'Tout sélectionner',
  selectArea: 'Choisissez une zone géographique',
  selectFiles: 'Sélectionner les fichiers',
  show: `Afficher`,
  showLess: 'Afficher moins',
  showMore: 'Afficher plus',
  source: 'Source',
  sources: 'Sources',
  statisticalFactsheets: 'Fiches statistiques',
  status: 'Statut',
  submit: 'Envoyer',
  submittedForApproval: 'Soumis pour approbation',
  submittedToReview: 'Soumis pour examen',
  success: 'Succès',
  tier: 'Niveau',
  tiers: { notSelected: '', high: 'Elevé', medium: 'Moyen', low: 'Faible' },
  totalPercentage: 'Pourcentage total',
  trend: 'Tendances',
  unknown: 'Aucune fonction/Fonction inconnue',
  unselectAll: 'Tout déselectionner',
  updated: 'Mis à jour',
  userGuide: "Guide de l'utilisateur",
  variable: 'FRA Variable',
  view: 'Voir',
  year: 'Année',
}
