module.exports = {
  accepted: 'Accepted',
  add: 'Add',
  all: 'All',
  apply: 'Apply',
  approve: 'Approve',
  approved: 'Approved',
  areYouSureYouWantToDelete: 'Are you sure you want to delete this item? This operation cannot be undone.',
  areYouSureYouWantToDeleteAllTableData: 'Are you sure you want to delete all data from the table?',
  calculate: 'Calculate',
  cancel: 'Cancel',
  clearTable: 'Clear table',
  column: 'Column',
  continue: 'Continue',
  copyToClipboard: 'Copy to clipboard',
  countries: 'Countries',
  country: 'Country',
  custom: 'Custom',
  dashboard: 'Dashboard',
  dataExport: 'Data Export',
  delete: 'Delete',
  disapprove: 'Disapprove',
  done: 'Done',
  email: 'Email',
  empty: 'Empty',
  expired: 'Expired',
  file: 'File',
  filterCountries: 'Filter countries',
  fraPlatform: 'Fra platform',
  globalFRA: 'Global Forest Resources Assessment',
  hide: 'Hide',
  invitations: 'Invitations',
  invited: 'Invited',
  issues: 'Issues',
  label: 'Label',
  language: 'Language',
  lastEdit: 'Last edit',
  latest: 'Latest',
  link: 'Link',
  load: 'Load',
  loading: 'Loading...',
  login: 'Reserved area',
  name: 'Name',
  noItemsFound: 'No items found',
  or: 'Or',
  other: 'Other',
  otherSpecifyInComments: 'Other (specify in comments)',
  private: 'Private',
  public: 'Public',
  regions: 'Regions',
  resetAll: 'Reset all',
  role: 'Role',
  select: 'Select',
  selectAll: 'Select all',
  selectArea: 'Select geographical area',
  selectFiles: 'Select files',
  show: 'Show',
  showLess: 'Show less',
  showMore: 'Show more',
  source: 'Source',
  sources: 'Sources',
  statisticalFactsheets: 'Statistical factsheets',
  status: 'Status',
  submit: 'Submit',
  submittedForApproval: 'Submitted for approval',
  submittedToReview: 'Submitted to review',
  success: 'Success',
  tier: 'Tier',
  tiers: { high: 'High', low: 'Low', medium: 'Medium', notSelected: '' },
  total: 'Total',
  totalPercentage: 'Total percentage',
  trend: 'Trend',
  unknown: 'None/unknown',
  unselectAll: 'Unselect all',
  updated: 'Updated',
  userGuide: 'User guide',
  variable: 'FRA Variable',
  view: 'View',
  year: 'Year',
  yes: 'Yes',
}
